import * as R from 'ramda'
import { createStore as reduxCreateStore } from "redux"
import * as Actions from "./actions"
import { Model, Days, Cinema, Movies, Movie, CinemasMap, Show, Day, Shows, CinemaId } from "./model"
import moment from 'moment'
import { assertNotNull, isEmpty, assertNever } from './utils';
import { isSSR as isSSRfn } from '../components/utils'

export const FLAGS = {
  newsletter: (mainCinemaId: CinemaId): 'newsletter' | 'none' | 'whatsapp' =>
    mainCinemaId === 'odeon6' ? 'newsletter' : 'whatsapp'
}

export const reducer = (model: Model, action: Actions.Action): Model => {
  if (action.type.startsWith('@@redux')) {
    return model
  }
  try {
    switch (action.type) {
      case 'HIGHLIGHT':
        return highlightReducer(model, action)
      case 'SELECT_DAY':
        return selectDayReducer(model, action)
      case 'SELECT_CINEMA':
        return selectCinemaReducer(model, action)
      case 'SELECT_SHOW':
        return model //noop
      case 'TICK':
        return tickReducer(model, action)
      case 'UPDATING':
      case 'REFRESH':
        return updateReducer(model, action)
      case 'SELECT_SORT':
        return sortReducer(model, action)
      default:
        assertNever(action)
        throw new Error('Unexpected action')
    }
  } catch (e) {
    console.error('MDS - Error while updating model', model, action, e)
    return model
  }
}

const sortReducer  = (model: Model, action: Actions.SelectSort): Model => {
  switch (model.page.id) {
    case 'events':
      return action.sortBy === model.page.sortBy
      ? model
      : {
        ...model,
        page: {
          ...model.page,
          sortBy: action.sortBy
        }
      }
    case 'home':
    case 'programmazione':
    case 'movie':
      throw new Error('Unexpected state')
    default:
      const _: never = model.page
      throw new Error('Unexpected state')
  }
}

const updateReducer = (model: Model, action: Actions.Refresh | Actions.Updating): Model => {
  switch (action.type) {
    case 'REFRESH':
      return {
        ...R.clone(model),
        isUpdating: false
      }
    case 'UPDATING':
      return {
        ...model,
        isUpdating: true
      }
    default:
      const a: never = action
      throw new Error('Unexpected action')
  }
}

const selectDayReducer = (model: Model, action: Actions.SelectDay): Model => {
  switch (model.page.id) {
    case 'home':
      return {
        ...model,
        page: {
          ...model.page,
          selectedDay: action.day
        }
      }
    case 'movie':
      const cinema = assertNotNull(model.page.selectedCinema)
      const showsOnSelectedDay = Shows.isOnDay(action.day)
      const availableShows = model.page.cinemaShows[cinema]
        .filter(showsOnSelectedDay)
      return availableShows.length === 0
        ? model
        : {
          ...model,
          page: {
            ...model.page,
            selectedDay: action.day
          }
      }
    case 'programmazione':
    case 'events':
      throw new Error('Unexpected state')
    default:
      assertNever(model.page)
      throw new Error('Unexpected state')
  }
}

const selectCinemaReducer = (model: Model, action: Actions.SelectCinema): Model => {
  switch (model.page.id) {
    case 'movie':
      const isActiveDay = Days.isEqualOrAfter(Days.fromMoment(model.now))
      const shows = Movies.allShows(model.page.movie)
      const activeCinemaShows: CinemasMap<Show[]> = {
        mds: shows.filter(s => s.cinemaId === 'mds' && isActiveDay(s.day)),
        odeon6: shows.filter(s => s.cinemaId === 'odeon6' && isActiveDay(s.day))
      }

      const selectedCinema = activeCinemaShows[action.cinema].length > 0
        ? action.cinema
        : R.find(k => activeCinemaShows[k].length > 0, R.keys(activeCinemaShows)) || null

      const activeDays = selectedCinema == null
        ? []
        : activeCinemaShows[selectedCinema]
            .map(s => s.day)
            .sort(Days.difference)

      const selectedDay = Movies.getShows(model.page.movie, model.page.selectedDay, selectedCinema).length > 0
          ? model.page.selectedDay
          : activeDays[0]

      return {
        ...model,
        page: {
          ...model.page,
          selectedCinema: selectedCinema,
          selectedDay: selectedDay,
          cinemaShows: activeCinemaShows
        }
      }
    case 'home':
    case 'programmazione':
    case 'events':
      throw new Error('Unexpected state')
    default:
      assertNever(model.page)
      throw new Error('Unexpected state')
  }
}

const highlightReducer = (model: Model, action: Actions.Highlight): Model => {
  switch (model.page.id) {
    case 'home':
    case 'programmazione':
      return {
        ...model,
        page: {
          ...model.page,
          highlight: action.highlight
        }
      }
    case 'movie':
    case 'events':
      throw new Error('Unexpected state')
    default:
      assertNever(model.page)
      throw new Error('Unexpected state')
  }
}

const REFRESH_THRESHOLD = 60 * 10  // 10m
const tickReducer = (model: Model, action: Actions.Tick): Model => {
  if (action.now.unix() - model.session.lastUpdated > REFRESH_THRESHOLD) {
    window.location.reload()
  }
  return {
    ...model,
    now: action.now || moment()
  }
}

const createStore = (type: 'home' | 'programmazione' | 'movie' | 'events', cinemas: Cinema[], movie?: Movie) => {
  const isSSR = isSSRfn()
  const now = isSSR
    ? moment().set('hour', 6).set('minute', 0)    // this is what's used by SSR
    : moment()                                    // this is used normally on the client
  const today = Days.fromMoment(now)

  const nowShowing = cinemas.reduce( (acc, c) => [
      ...acc,
      ...c.movies.sort(Movies.sortNowShowingMovies(null))
    ], [])
    .filter(Movies.hasActiveShows(today))
    .reduce((acc: Movie[], movie) => {
      // I am sorry
      const existing = R.find(m => m.id === movie.id, acc)
      if (existing) {
        const daysToMerge = R.keys(movie.shows) as string[]
        daysToMerge.forEach( day => {
          existing[day] = [...(existing[day] || []), ... movie.shows[day]]
        })
        return acc
      }
      return [
        ...acc,
        movie
      ]
    }, [])
  const comingSoon = Movies.computeComingSoon(
    cinemas.reduce( (acc, c) => [...acc, ...c.moviesComingSoon], []),
    today,
    nowShowing.filter(Movies.hasShowsOn(today)).map(m => m.id)
  )
  const extraMovies = cinemas.reduce( (acc, c) => [
    ...acc,
    ...c.extraMovies
  ], [])

  const events = R.uniqBy( m => m.id,
    [...nowShowing, ...comingSoon]
      .filter( m => m.event !== null && !isEmpty(m.event.type) )
  )

  const common = {
    now: now,
    isSSR,
    nowShowing,
    comingSoon,
    extraMovies,
    events: events,
    isUpdating: false,
    session: {
      started: now.unix(),
      lastUpdated: now.unix()
    }
  }

  switch (type) {
    case 'home':
      const initialHome: Model = {
        ...common,
        page: {
          id: 'home',
          highlight: null,
          selectedDay: Days.fromMoment(now)
        }
      }

      return reduxCreateStore(reducer, initialHome)
    case 'programmazione':
      const initialProgrammazione: Model = {
        ...common,
        page: {
          id: 'programmazione',
          highlight: null,
          selectedDay: Days.fromMoment(now)
        }
      }
      return reduxCreateStore(reducer, initialProgrammazione)

    case 'movie':
      if (movie == null) {
        throw new Error('Movie cannot be null');
      }
      const isActiveDay = Days.isEqualOrAfter(Days.fromMoment(now))
      const shows = Movies.allShows(movie)
      const activeCinemaShows: CinemasMap<Show[]> = {
        mds: shows.filter(s => s.cinemaId === 'mds' && isActiveDay(s.day)),
        odeon6: shows.filter(s => s.cinemaId === 'odeon6' && isActiveDay(s.day))
      }
      const selectedCinema = cinemas.find(c => activeCinemaShows[c.id].length > 0)
      const selectedDay = selectedCinema != null
        ? [...activeCinemaShows[selectedCinema.id].map(s => s.day).sort(Days.difference), null][0]
        : null
      const initialMovie: Model = {
        ...common,
        page: {
          id: 'movie',
          movie: movie,
          selectedDay: selectedDay,
          selectedCinema: selectedCinema != null
            ? selectedCinema.id
            : null,
          cinemaShows: activeCinemaShows
        }
      }
      return reduxCreateStore(reducer, initialMovie)

    case 'events':
      const initialEvents: Model = {
        ...common,
        page: {
          id: 'events',
          sortBy: 'DATE',
        }
      }
      return reduxCreateStore(reducer, initialEvents)

    default:
      assertNever(type)
      throw new Error('should not happen')
  }
}

const bootstrap = (type: 'home' | 'programmazione' | 'movie' | 'events', cinemas: Cinema[], mainCinemaId: CinemaId, startTicker: boolean, movie?: Movie) => {
  const store = createStore(type, cinemas, movie);
  // (only run ticker and update listeners on the client)
  const { isSSR } = store.getState()
  if (!isSSR) {
    // TODO Remove
    (window as any).store = store

    // see gatsby-browser for triggers
    window.addEventListener('serviceWorkerUpdateFound', () => {
      store.dispatch(Actions.Actions.updating())
      // Safety in case reloading takes too long
      setTimeout(() => {
        window.document.location!.reload()
      }, 10000)
    }, false)
    window.addEventListener('serviceWorkerActive', () => {
      window.document.location!.reload()
    }, false)
    window.addEventListener('initialClientRender', () => {
      store.dispatch(Actions.Actions.refresh())
    }, false)

    if (startTicker) {
      // tick every minute
      setInterval( () => {
        store.dispatch(Actions.Actions.tick())
      }, 1000 * 60)
      // tick everytime the page gets focus / is visible
      dispatchTickOnVisibility(store)
    }
  }
  return store;
}

function dispatchTickOnVisibility(store) {
  var doc = (window.document as any)
  // Set the name of the hidden property and the change event for visibility
  var hidden, visibilityChange;
  if (typeof doc.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof doc.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof doc.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  if (hidden !== undefined) {
    // Handle page visibility change
    doc.addEventListener(visibilityChange, () => {
      const isPageVisible = document[hidden] === false
      if (isPageVisible) {
        store.dispatch(Actions.Actions.tick())
      }
    }, false);
  }
}

export default bootstrap
