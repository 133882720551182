import { Movie, Day, Show, CinemaId } from "./model"
import moment, { Moment } from "moment"


interface SuccessfulActionResult<R> {
  type: 'success'
  payload: R
}
interface FailedActionResult<E> {
  type: 'error'
  error: E
}
export type ActionResult<R, E> = SuccessfulActionResult<R> | FailedActionResult<E>


export interface Highlight {
  type: 'HIGHLIGHT'
  highlight: Movie | null
}

export interface Tick {
  type: 'TICK'
  now: Moment
}

export interface SelectDay {
  type: 'SELECT_DAY'
  day: Day
}

export interface SelectCinema {
  type: 'SELECT_CINEMA'
  cinema: CinemaId
}

export interface SelectShow {
  type: 'SELECT_SHOW'
  show: Show
}

export interface SelectSort {
  type: 'SELECT_SORT'
  sortBy: 'TYPE' | 'DATE'
}

export interface Updating {
  type: 'UPDATING'
}

export interface Refresh {
  type: 'REFRESH'
}

export type Action = Highlight
  | SelectDay
  | SelectShow
  | SelectCinema
  | SelectSort
  | Tick
  | Updating
  | Refresh

export const Actions = {
  highlightMovie: (movie: Movie): Highlight => ({
    type: 'HIGHLIGHT',
    highlight: movie
  }),
  selectDay: (day: Day): SelectDay => ({
    type: 'SELECT_DAY',
    day: day
  }),
  selectCinema: (cinemaId: CinemaId): SelectCinema => ({
    type: 'SELECT_CINEMA',
    cinema: cinemaId
  }),
  selectSort: (sortBy: 'TYPE' | 'DATE'): SelectSort => ({
    type: 'SELECT_SORT',
    sortBy: sortBy
  }),
  tick: (): Tick => ({
    type: 'TICK',
    now: moment()
  }),
  updating: (): Updating => ({
    type: 'UPDATING'
  }),
  refresh: (): Refresh => ({
    type: 'REFRESH'
  })
}